import(/* webpackMode: "eager" */ "/vercel/path0/apps/sdkode/src/app/sdkode.css");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/vercel/path0/apps/sdkode/src/components/layout/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__r_zlewoglhtblawys2or4ro7lubi/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0_xc2npjf4br5uvb4fx6y3iwkfny/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.4_next@15.1.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0._exs665h27elqptch35o23b4kom/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/layout/theme-controller/theme-controller.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/packages/ui/src/providers/theme/theme-provider.tsx");
