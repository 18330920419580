'use client';

import { Link } from 'next-view-transitions';
import { usePathname } from 'next/navigation';
import { HTMLAttributeAnchorTarget } from 'react';

import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	navigationMenuTriggerStyle,
} from '@ui/components/ui/navigation-menu';

type MenuItem = {
	title: string;
	href: string;
	target?: HTMLAttributeAnchorTarget;
};

const menuItems: MenuItem[] = [
	{ title: 'Portfolio', href: '/portfolio' },
	{ title: 'Resume', href: '/resume' },
	{ title: 'Schedule call', href: '/schedule', target: '_blank' },
];

const externalUrls = ['/schedule', '/calendar.app.google.com'];

export function Menu() {
	const pathname = usePathname();

	return (
		<NavigationMenu>
			<NavigationMenuList>
				{menuItems.map(({ title, href, target = '_self' }) => {
					const isActive = pathname === href;
					const prefetch = externalUrls.includes(href);

					return (
						<NavigationMenuItem key={`menu-${title}`}>
							<NavigationMenuLink asChild active={isActive} className={navigationMenuTriggerStyle()}>
								<Link title={`Navigate to ${title}`} prefetch={prefetch} href={href} target={target}>
									{title}
								</Link>
							</NavigationMenuLink>
						</NavigationMenuItem>
					);
				})}
			</NavigationMenuList>
		</NavigationMenu>
	);
}
